import React from 'react';
import { Container } from 'emotion-flex';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';

import SEO from '../components/SEO';
import EmptyState from '../components/EmptyState';
import Link from '../components/Link';
import Button from '../components/Button';
import { withLayout } from '../components/Layout';
import { useBrowserLocale } from '../hooks';

const ButtonLink = Button.withComponent(Link);

const EmailWrongLink: React.FC<WrappedComponentProps> = ({ intl }) => {
  useBrowserLocale();

  return (
    <>
      <SEO
        title={intl.formatMessage({
          id: 'pages.email.errorVerifyingEmail',
          defaultMessage: 'Error verifying email address',
        })}
      />
      <Container>
        <EmptyState
          title={intl.formatMessage({
            id: 'pages.email.errorVerifyingEmail',
            defaultMessage: 'Error verifying email address',
          })}
          text={
            <div>
              <p>
                <FormattedMessage
                  id="pages.email.emailConfirmedWithDifferentAccount"
                  defaultMessage="This email address was confirmed with a different user account."
                />
              </p>
            </div>
          }
          action={
            <ButtonLink to="https://cara.app.link/cOPiuABldy">
              <FormattedMessage
                id="pages.email.continueToApp"
                defaultMessage="Continue to App"
              />
            </ButtonLink>
          }
          withImage
        />
      </Container>
    </>
  );
};

export default withLayout(injectIntl(EmailWrongLink), { isNewLayout: true });
